<script setup lang="ts">
    import Dialog from 'primevue/dialog';
    import { useI18n } from 'vue-i18n';
    import FormField from '@/components/form-components/FormField.vue';
    import Button from 'primevue/button';
    import Textarea from 'primevue/textarea';
    import Dropdown from 'primevue/dropdown';
    import { LeadDto, LeadActivity } from '@containex/portal-backend-dto';
    import { nativeEnum, object, string } from 'zod';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import { leadsApi } from '@containex/portal-backend-api-client';
    import { httpClient } from '@/common/api/http-client';
    import { getLogger } from '@containex/logger';
    import { HttpStatusCode } from 'axios';
    import { isMobile } from '@/util/breakpoints';
    import { hasValidationError } from '@/util/hasValidationError';
    import { isStringEmpty } from '@containex/common-utils';

    const props = defineProps<{
        isVisible: boolean;
        lead: LeadDto;
    }>();

    const emits = defineEmits<{
        close: [];
        update: [LeadDto];
    }>();

    const { t } = useI18n();
    const logger = getLogger('EditLeadDialog');
    const activities = [
        LeadActivity.OrderSales,
        LeadActivity.OrderRental,
        LeadActivity.OrderCompetitor,
        LeadActivity.OfferPriceInfo,
        LeadActivity.OfferRental,
        LeadActivity.OfferSales,
        LeadActivity.NotInteresting,
        LeadActivity.NotPossible,
    ];

    const schema = object({
        internalNote: string().trim().optional(),
        activityStatus: nativeEnum(LeadActivity).optional(),
        activityText: string().refine(validateActivityText, {
            message: t('ACCOUNT.EDIT_LEADS.ACTIVITY_TEXT_VALIDATION_MESSAGE'),
        }),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [internalNote] = defineField('internalNote');
    const [activityStatus] = defineField('activityStatus');
    const [activityText] = defineField('activityText');

    if (props.lead != null) {
        internalNote.value = props.lead.internalNote ?? '';
        activityStatus.value = props.lead.activity ?? undefined;
        activityText.value = props.lead.activityText ?? '';
    }

    const onSubmit = handleSubmit(async (values) => {
        try {
            const text = values.activityStatus === LeadActivity.NotInteresting ? values.activityText : undefined;
            const activity = values.activityStatus != null ? { status: values.activityStatus, text } : undefined;

            const response = await leadsApi.updateLead(httpClient, props.lead.id, {
                internalNote: values.internalNote,
                activity,
            });

            if (response.status === HttpStatusCode.Ok) {
                emits('update', response.data);
            } else {
                logger.error('Error while trying to update lead', response);
            }
        } catch (error) {
            logger.error('Error while trying to update lead', error);
        }
    });

    function translateActivity(activity: LeadActivity): string {
        return t(`ACCOUNT.LEADS.ACTIVITIES.${activity}`);
    }

    function validateActivityText(text: string): boolean {
        return !(activityStatus.value === LeadActivity.NotInteresting && isStringEmpty(text));
    }
</script>

<template>
    <Dialog
        :visible="isVisible"
        modal
        :style="{ width: '40rem', 'max-width': '100%' }"
        :pt="{ closeButton: { onClick: () => emits('close') } }"
        @hide="emits('close')"
    >
        <template #header>
            <div class="dialog-header-typography lead-header">{{ t('ACCOUNT.EDIT_LEADS.HEADER') }}</div>
        </template>
        <form class="flex-container">
            <div class="row">
                <FormField :label="t('ACCOUNT.EDIT_LEADS.NOTE')" :error-message="errors.internalNote">
                    <Textarea v-model="internalNote" rows="6" class="full-width internal-notes-textarea" />
                </FormField>
            </div>
            <div class="row">
                <FormField :label="t('ACCOUNT.EDIT_LEADS.ACTIVITY')">
                    <Dropdown
                        v-model="activityStatus"
                        class="full-width"
                        data-testid="signup-form-country"
                        :options="activities"
                        :option-label="translateActivity"
                        :invalid="hasValidationError(errors.activityStatus)"
                    />
                </FormField>
            </div>
            <div v-if="activityStatus === LeadActivity.NotInteresting" class="row">
                <FormField :label="t('ACCOUNT.EDIT_LEADS.ACTIVITY_TEXT')" :error-message="errors.activityText">
                    <Textarea
                        v-model="activityText"
                        rows="6"
                        class="full-width internal-notes-textarea"
                        :invalid="hasValidationError(errors.activityText)"
                    />
                </FormField>
            </div>
            <div class="row">
                <div class="upload-section-header upload-row">
                    <Button
                        type="button"
                        icon="pi pi-plus"
                        :label="!isMobile ? t('ACCOUNT.EDIT_LEADS.CHOOSE_FILE') : ''"
                    />
                    <Button
                        type="button"
                        disabled
                        icon="pi pi-upload"
                        outlined
                        :label="!isMobile ? t('COMMON.UPLOAD') : ''"
                    />
                    <Button
                        type="button"
                        disabled
                        icon="pi pi-times"
                        outlined
                        :label="!isMobile ? t('COMMON.CANCEL') : ''"
                    />
                </div>
                <div class="upload-section-content">{{ t('ACCOUNT.EDIT_LEADS.NO_UPLOADS') }}</div>
            </div>
        </form>
        <div class="lead-footer">
            <Button type="button" outlined :label="t('COMMON.CANCEL')" @click="emits('close')" />
            <Button type="submit" :label="t('COMMON.SAVE')" @click="onSubmit" />
        </div>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .lead-footer {
        display: flex;
        gap: 1ch;
        padding-bottom: main.$spacing-5;
        justify-content: flex-end;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .internal-notes-textarea {
        resize: none;
        display: block;
    }

    .flex-container {
        display: flex;
        flex-direction: column;
        padding-bottom: main.$spacing-6;
    }

    .row {
        display: flex;
        flex-direction: column;
        column-gap: main.$spacing-5;
    }

    .upload-row {
        margin-top: main.$spacing-7;
    }

    .upload-section-header {
        background: #f9fafb;
        border-radius: 3px 3px 0 0;
        border: 1px solid #e5e7eb;
        padding: main.$spacing-6;
        display: flex;
        justify-content: left;
        gap: main.$spacing-3;
    }

    .upload-section-content {
        border-radius: 0 0 3px 3px;
        border: 1px solid #e5e7eb;
        border-top: 0;
        padding: main.$spacing-6;
    }
</style>
